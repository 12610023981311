import { ALL_CATEGORIES, ALL_HONORS, ALL_TILES, CHI_SAFE_NUMBERS, DRAGONS, ERROR_TILE, NUMBERS, SUITS, TILES, WINDS, getTileKey } from './TileConstants'

const PONG = 'PONG'
const CHI = 'CHI'
const PONG_CHI = [PONG, CHI]
const pickAny = (list) => {
    return list && list[Math.floor(Math.random() * list.length)];
}

const shuffle = (list) => {
    const array = [...list]
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array
}

const chiFromDeckIfSafe = (tileKey, deck) => {
    if (!deck) {
        return true
    }
    const [face, number] = tileKey.split('-')
    if (number === undefined) {
        return false
    }
    const numTakenFirst = deck[getTileKey(face, number)]
    const numTakenSecond = deck[getTileKey(face, number + 1)]
    const numTakenThird = deck[getTileKey(face, number + 2)]
    if ((numTakenFirst === undefined || numTakenFirst < 4)
            && (numTakenSecond === undefined || numTakenSecond < 4)
            && (numTakenThird === undefined || numTakenThird < 4)) {
        
        deck[tileKey] = numTakenFirst === undefined ? 1 : numTakenFirst + 1
        deck[tileKey + 1] = numTakenSecond === undefined ? 1 : numTakenSecond + 1
        deck[tileKey + 2] = numTakenThird === undefined ? 1 : numTakenThird + 1

        return true
    }
    return false
}

const pongFromDeckIfSafe = (tileKey, deck) => {
    if (!deck) {
        return true
    }
    const numTaken = deck[tileKey]
    if (numTaken === undefined) {
        deck[tileKey] = 3
        return true
    }
    if (numTaken < 2) {
        deck[tileKey] += 3
        return true
    }
    return false
}

const pairFromDeckIfSafe = (tileKey, deck) => {
    if (!deck) {
        return true
    }
    const numTaken = deck[tileKey]
    if (numTaken === undefined) {
        deck[tileKey] = 2
        return true
    }
    if (numTaken < 3) {
        deck[tileKey] += 2
        return true
    }
    return false
}

const createChi = ({suit, deck}) => {
    if (suit) {
        for (let i = 0; i < 10; i++) {
            const chosenTile = pickAny(CHI_SAFE_NUMBERS)
            if (!chiFromDeckIfSafe(getTileKey(suit, chosenTile), deck)) continue
            return [TILES[suit][chosenTile], TILES[suit][chosenTile + 1], TILES[suit][chosenTile + 2]]
        }
    } else {
        for (let i = 0; i < 10; i++) {
            const chosenCategory = pickAny(SUITS)
            const chosenTile = pickAny(CHI_SAFE_NUMBERS)
            if (!chiFromDeckIfSafe(getTileKey(chosenCategory, chosenTile), deck)) continue
            return [TILES[chosenCategory][chosenTile], TILES[chosenCategory][chosenTile + 1], TILES[chosenCategory][chosenTile + 2]]
        }
    }
    return [ERROR_TILE, ERROR_TILE, ERROR_TILE]
}

const createPong = ({suit, number, category, tile, deck}) => {
    if (tile) {
        return [tile, tile, tile]
    }
    else if (number) {
        for (let i = 0; i < 10; i++) {
            const chosenSuit = suit || pickAny(SUITS)
            if (!pongFromDeckIfSafe(getTileKey(chosenSuit, number), deck)) continue
            return [TILES[chosenSuit][number], TILES[chosenSuit][number], TILES[chosenSuit][number]]
        }
    }
    else if (suit) {
        for (let i = 0; i < 10; i++) {
            const chosenTile = pickAny(NUMBERS)
            if (!pongFromDeckIfSafe(getTileKey(suit, chosenTile), deck)) continue
            return [TILES[suit][chosenTile], TILES[suit][chosenTile], TILES[suit][chosenTile]]
        }
    }
    else if (category) {
        for (let i = 0; i < 10; i++) {
            const chosenCategory = pickAny(category)
            if (SUITS.includes(chosenCategory)) {
                const chosenTile = pickAny(NUMBERS)
                if (!pongFromDeckIfSafe(getTileKey(chosenCategory, chosenTile), deck)) continue
                return [TILES[chosenCategory][chosenTile], TILES[chosenCategory][chosenTile], TILES[chosenCategory][chosenTile]]
            }
            if (DRAGONS.includes(chosenCategory)) {
                if (!pongFromDeckIfSafe(getTileKey(chosenCategory), deck)) continue
                return [TILES.JIAN[chosenCategory], TILES.JIAN[chosenCategory], TILES.JIAN[chosenCategory]]
            }
            if (WINDS.includes(chosenCategory)) {
                if (!pongFromDeckIfSafe(getTileKey(chosenCategory), deck)) continue
                return [TILES.FENG[chosenCategory], TILES.FENG[chosenCategory], TILES.FENG[chosenCategory]]
            }
        }
    } else {
        for (let i = 0; i < 10; i++) {
            const tileKey = pickAny(ALL_TILES)
            const [chosenCategory, chosenTile] = tileKey.split('-')
            if (SUITS.includes(chosenCategory)) {
                if (!pongFromDeckIfSafe(tileKey, deck)) continue
                return [TILES[chosenCategory][chosenTile], TILES[chosenCategory][chosenTile], TILES[chosenCategory][chosenTile]]
            }
            if (DRAGONS.includes(chosenCategory)) {
                if (!pongFromDeckIfSafe(tileKey, deck)) continue
                return [TILES.JIAN[chosenCategory], TILES.JIAN[chosenCategory], TILES.JIAN[chosenCategory]]
            }
            if (WINDS.includes(chosenCategory)) {
                if (!pongFromDeckIfSafe(tileKey, deck)) continue
                return [TILES.FENG[chosenCategory], TILES.FENG[chosenCategory], TILES.FENG[chosenCategory]]
            }
        }
    }
    return [ERROR_TILE, ERROR_TILE, ERROR_TILE]
}

const createPongOrChi = ({ suit, deck }) => {
    const pongOrChi = pickAny(PONG_CHI)
    if (pongOrChi === PONG) {
        return createPong({ suit, deck })
    } else {
        return createChi({ suit, deck })
    }
}

const createPair = ({suit, number, category, tile, deck}) => {
    if (tile) {
        return [tile, tile]
    }
    else if (number) {
        for (let i = 0; i < 10; i++) {
            const chosenSuit = suit || pickAny(SUITS)
            if (!pairFromDeckIfSafe(getTileKey(chosenSuit, number), deck)) continue
            return [TILES[chosenSuit][number], TILES[chosenSuit][number]]
        }
    }
    else if (suit) {
        for (let i = 0; i < 10; i++) {
            const chosenTile = pickAny(NUMBERS)
            if (!pairFromDeckIfSafe(getTileKey(suit, chosenTile), deck)) continue
            return [TILES[suit][chosenTile], TILES[suit][chosenTile]]
        }
    }
    else if (category) {
        for (let i = 0; i < 10; i++) {
            const chosenCategory = pickAny(category)
            if (SUITS.includes(chosenCategory)) {
                const chosenTile = pickAny(NUMBERS)
                if (!pairFromDeckIfSafe(getTileKey(chosenCategory, chosenTile), deck)) continue
                return [TILES[chosenCategory][chosenTile], TILES[chosenCategory][chosenTile]]
            }
            if (DRAGONS.includes(chosenCategory)) {
                if (!pairFromDeckIfSafe(getTileKey(chosenCategory), deck)) continue
                return [TILES.JIAN[chosenCategory], TILES.JIAN[chosenCategory]]
            }
            if (WINDS.includes(chosenCategory)) {
                if (!pairFromDeckIfSafe(getTileKey(chosenCategory), deck)) continue
                return [TILES.FENG[chosenCategory], TILES.FENG[chosenCategory]]
            }
        }
    } else {
        for (let i = 0; i < 10; i++) {
            const tileKey = pickAny(ALL_TILES)
            const [chosenCategory, chosenTile] = tileKey.split('-')
            if (SUITS.includes(chosenCategory)) {
                if (!pairFromDeckIfSafe(tileKey, deck)) continue
                return [TILES[chosenCategory][chosenTile], TILES[chosenCategory][chosenTile]]
            }
            if (DRAGONS.includes(chosenCategory)) {
                if (!pairFromDeckIfSafe(tileKey, deck)) continue
                return [TILES.JIAN[chosenCategory], TILES.JIAN[chosenCategory]]
            }
            if (WINDS.includes(chosenCategory)) {
                if (!pairFromDeckIfSafe(tileKey, deck)) continue
                return [TILES.FENG[chosenCategory], TILES.FENG[chosenCategory]]
            }
        }
    }
    return [ERROR_TILE, ERROR_TILE]
}

export const createChickenHand = () => {
    const deck = {}
    const categoriesList = shuffle([...SUITS, pickAny(ALL_CATEGORIES)])
    const handList = shuffle(['PONG', 'PONG', 'CHI', 'CHI'])

    return [
        ...handList.map((hand, i) => {
            if (hand === 'PONG' || ALL_HONORS.includes(categoriesList[i])) {
                return createPong({ category: [categoriesList[i]], deck })
            } else {
                return createChi({ suit: categoriesList[i], deck })
            }
        }),
        createPair({ deck })
    ]
}

export const createCommonHand = () => {
    const deck = {}

    return [
        createChi({ deck }),
        createChi({ deck }),
        createChi({ deck }),
        createChi({ deck }),
        createPair({ deck })
    ]
}

export const createAllPongsHand = () => {
    const deck = {}

    return [
        createPong({ deck }),
        createPong({ deck }),
        createPong({ deck }),
        createPong({ deck }),
        createPair({ deck })
    ]
}

export const createMixedOneSuitHand = () => {
    const deck = {}
    const suit = pickAny(SUITS)
    const categoriesList = shuffle([suit, suit, suit, suit, pickAny(ALL_HONORS)])
    const handList = shuffle([pickAny(PONG_CHI), pickAny(PONG_CHI), pickAny(PONG_CHI), CHI])

    return [
        ...handList.map((hand, i) => {
            if (hand === PONG || ALL_HONORS.includes(categoriesList[i])) {
                return createPong({ category: [categoriesList[i]], deck })
            } else {
                return createChi({ suit, deck })
            }
        }),
        createPair({ category: [categoriesList[4]], deck })
    ]
}

export const createAllPairsHand = () => {
    const deck = {}

    return [
        ...CHI_SAFE_NUMBERS.map(() => createPair({ deck }))
    ]
}

export const createSmallDragonsHand = () => {
    const deck = {}
    const dragonOrder = shuffle(DRAGONS)
    const dragonMelds = dragonOrder.map((dragon, i) => i === 2 ? createPair({ category: [dragon], deck }) : createPong({ category: [dragon], deck }))

    return [
        createPongOrChi({ deck }),
        createPongOrChi({ deck }),
        ...dragonMelds
    ]
}

export const createSmallWindsHand = () => {
    const deck = {}
    const windOrder = shuffle(WINDS)
    const windMelds = windOrder.map((wind, i) => i === 3 ? createPair({ category: [wind], deck }) : createPong({ category: [wind], deck }))

    return [
        createPongOrChi({ deck }),
        ...windMelds
    ]
}

export const createAllOneSuitHand = () => {
    const deck = {}
    const suit = pickAny(SUITS)

    return [
        createPongOrChi({ suit, deck}),
        createPongOrChi({ suit, deck}),
        createPongOrChi({ suit, deck}),
        createPongOrChi({ suit, deck}),
        createPair({ suit, deck })
    ]
}

export const createBigDragonsHand = () => {
    const deck = {}
    const dragonOrder = shuffle(DRAGONS)

    return [
        ...dragonOrder.map(dragon => createPong({ category: [dragon], deck })),
        createPongOrChi({ deck }),
        createPair({ deck })
    ]
}

export const createTerminalsHand = () => {
    const deck = {}
    const meldOrder = shuffle([1, 1, 1, 9, 9, 9, ...shuffle(ALL_HONORS).slice(0, 2)]).slice(0, 5)

    return meldOrder.map((type, i) => {
        if (i === 4) {
            if (ALL_HONORS.includes(type)) {
                return createPair({ category: [type], deck })
            } else {
                return createPair({ number: type, deck })
            }
        }
        if (ALL_HONORS.includes(type)) {
            return createPong({ category: [type], deck })
        } else {
            return createPong({ number: type, deck })
        }
    })
}

export const createNineGatesHand = () => {
    const deck = {}
    const suit = pickAny(SUITS)

    return [
        createPong({ tile: TILES[suit][1], deck }),
        [
            TILES[suit][2],
            TILES[suit][3],
            TILES[suit][4],
            TILES[suit][5],
            TILES[suit][6],
            TILES[suit][7],
            TILES[suit][8],
        ],
        createPong({ tile: TILES[suit][9], deck }),
        [
            TILES[suit][pickAny(NUMBERS)]
        ]
    ]
}

export const createBigWindsHand = () => {
    const deck = {}
    const windOrder = shuffle(WINDS)

    return [
        ...windOrder.map(wind => createPong({ category: [wind], deck })),
        createPair({ deck })
    ]
}

export const createThirteenOrphansHand = () => {
    const winds = WINDS.map(wind => TILES.FENG[wind])
    const dragons = DRAGONS.map(dragon => TILES.JIAN[dragon])
    const suitPairs = SUITS.map(suit => [TILES[suit][1], TILES[suit][9]])
    const extraTile = [pickAny([...winds, ...dragons, ...suitPairs.reduce((collect, pair) => [...collect, ...pair], [])])
]
    return [
        winds,
        ...suitPairs,
        dragons,
        extraTile
    ]
}
