import React, { useState } from 'react'
import { keyframes, styled } from 'styled-components'
import { LabeledTile, Tile } from './tiles/Tile'
import { BING, DRAGONS, FENG, HUA, JIAN, LABELS, NUMBERS, TIAO, TILE_SCALES, WAN, WINDS } from './tiles/TileConstants'
import { createAllOneSuitHand, createAllPairsHand, createAllPongsHand, createBigDragonsHand, createBigWindsHand, createChickenHand, createCommonHand, createMixedOneSuitHand, createNineGatesHand, createSmallDragonsHand, createSmallWindsHand, createTerminalsHand, createThirteenOrphansHand } from './tiles/HandGenerator'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { v4 } from 'uuid'

const AppContainer = styled.div`
  margin: 0;
  background-color: #559c22;
  color: white;

  display: flex;
  flex-direction: column;
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const ConceptContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  scroll-snap-align: start;
`

const TitleConceptSection = styled(ConceptContainer)`
  background-color: #559c22;
  justify-content: center;
  align-items: center;

  height: calc(100% - 200px);
  min-height: calc(100% - 200px);
`

const TilesConceptSection = styled(ConceptContainer)`
  background-color: #13371f;
  flex-direction: column;
  padding-bottom: 200px;
`

const SubsectionHeading = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`

const SubsectionTitle = styled.h4`
  font-size: 3em;
  margin: 0;
  font-weight: 900;
  text-align: center;
`

const SubsectionSubtitle = styled.p`
  font-size: 3em;
  font-weight: 200;
  text-align: right;
  padding-right: 20px;
  margin-bottom: 36px;
  margin-top: -12px;
`

const TilesScrollable = styled.div`
  display: flex;
  overflow-x: scroll;
  gap: 20px;
  padding: 20px;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const HandOfTiles = styled.div`
  display: flex;
  overflow-x: none;
  padding: 36px 4px;
  justify-content: space-between;
  width: calc(100% - 8px);
  margin-top: 8px;
  
`

const TileSetInHand = styled.div`
  display: flex;
  overflow-x: none;
  gap: ${({ scale }) => scale < 1 ? 1 : scale * 3}px;

  background-color: #559c22;
  border-style: solid;
  border-color: #559c22;
  border-width: ${({ scale }) => scale < 1 ? 2 : scale * 6}px;
  border-radius: ${({ scale }) => 16 * scale}px;
  padding-bottom: ${({ scale }) => scale < 1 ? 22 : 24 - (scale * 6)}px;

  &.fade-enter {
    opacity: 0;
    transform: translateY(-50%);
  }
  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
  }
  &.fade-exit {
    opacity: 1;
    transform: translateY(0%);
  }
  &.fade-exit-active {
    opacity: 0;
    transform: translateY(50%);
  }
  &.fade-enter-active,
  &.fade-exit-active {
    transition: opacity 400ms, transform 400ms;
    transition-delay: ${({ idx = 0 }) => idx * 50}ms;
  }
`

const TileSet = ({ of, size, idx = 0 }) => {
  const scale = TILE_SCALES[size]

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={v4()}
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, false);
        }}
        classNames="fade"
        timeout={{ enter: 400 + (idx * 50), exit: 400 + (idx * 50) }}>
        <TileSetInHand scale={scale} idx={idx}>
          {
            of.map(tile => (
              <Tile is={tile} size={size} />
            ))
          }
        </TileSetInHand>
      </CSSTransition>
    </SwitchTransition>
  )
}

const Hand = ({ of, size }) => (
  <HandOfTiles>
    {
      of.map((set, idx) => (
        <TileSet size={size} of={set} idx={idx} />
      ))
    }
  </HandOfTiles>
)

const SmallAreaHeading = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 36px;
  min-width: 200px;
`

const DividingLineContainer = styled.div`
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
`

const TopDivider = styled.div`
  border-bottom: 2px white solid;
  height: 70%;
`

const BottomDivider = styled.div`
  display: none;
`

const DividingLine = () => (
  <DividingLineContainer>
    <TopDivider />
    <BottomDivider />
  </DividingLineContainer>
)

const ScrollableTitle = styled.h4`
  font-size: 3em;
  margin: 0;
  font-weight: 200;
  text-align: center;
`

const HandsConceptSection = styled(ConceptContainer)`
  flex-direction: column;
  background-color: #3112ae;
  padding-bottom: 200px;
`

const Text = styled.p`
  font-size: 2.8em;
  font-weight: 200;
  text-align: ${({ align = 'left' }) => align};
  padding: 0;
  margin: ${({ margin = 0 }) => margin};

  em {
    font-weight: bold;
    font-style: normal;
  }
`

const TileSetWithExplanation = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  justify-items: center;
  width: 100%;
  margin-bottom: 24px;
`

const TileSetExplanation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-self: left;
  justify-content: center;
`

const ScoringConceptSection = styled(ConceptContainer)`
  background-color: #bb0d55;
  flex-direction: column;
  padding-bottom: 200px;
`

const Title = styled.h1`
  font-size: 14vw;
  margin: 0;
  font-weight: 900;
`

const ConceptRow = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  padding-top: 48px;
  padding-bottom: 24px;
`

const ConceptTitle = styled.h2`
  text-align: center;
  font-size: 5em;
  margin: 0;
  font-weight: 900;
`

const SectionRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const TilesContent = () => (
  <>
    <SubsectionHeading>
      <SmallAreaHeading>
        <SubsectionTitle>序數牌</SubsectionTitle>
      </SmallAreaHeading>
      <DividingLine />
      <SmallAreaHeading>
        <SubsectionTitle>suits</SubsectionTitle>
      </SmallAreaHeading>
    </SubsectionHeading>
    <SubsectionSubtitle>4 of each tile</SubsectionSubtitle>

    <TilesScrollable>
      <SmallAreaHeading>
        <ScrollableTitle>萬子</ScrollableTitle>
        <ScrollableTitle>10,000s</ScrollableTitle>
      </SmallAreaHeading>
      {
        NUMBERS.map(i => (<LabeledTile is={WAN[i]} label={i} size="lg" key={'WAN-tile' + i} />))
      }
    </TilesScrollable>

    <TilesScrollable>
      <SmallAreaHeading>
        <ScrollableTitle>條子</ScrollableTitle>
        <ScrollableTitle>sticks</ScrollableTitle>
      </SmallAreaHeading>
      {
        NUMBERS.map(i => (<LabeledTile is={TIAO[i]} label={i} size="lg" key={'TIAO-tile' + i} />))
      }
    </TilesScrollable>

    <TilesScrollable>
      <SmallAreaHeading>
        <ScrollableTitle>大餅子</ScrollableTitle>
        <ScrollableTitle>cookies</ScrollableTitle>
      </SmallAreaHeading>
      {
        NUMBERS.map(i => (<LabeledTile is={BING[i]} label={i} size="lg" key={'bing-tile' + i} />))
      }
    </TilesScrollable>

    <SubsectionHeading>
      <SmallAreaHeading>
        <SubsectionTitle>字牌</SubsectionTitle>
      </SmallAreaHeading>
      <DividingLine />
      <SmallAreaHeading>
        <SubsectionTitle>honors</SubsectionTitle>
      </SmallAreaHeading>
    </SubsectionHeading>
    <SubsectionSubtitle>4 of each tile</SubsectionSubtitle>

    <TilesScrollable>
      <SmallAreaHeading>
        <ScrollableTitle>風牌</ScrollableTitle>
        <ScrollableTitle>winds</ScrollableTitle>
      </SmallAreaHeading>
      {
        WINDS.map(wind => (<LabeledTile is={FENG[wind]} label={LABELS[wind].en} size="lg" key={'wind-tile' + wind} />))
      }
    </TilesScrollable>

    <TilesScrollable>
      <SmallAreaHeading>
        <ScrollableTitle>箭牌</ScrollableTitle>
        <ScrollableTitle>dragons</ScrollableTitle>
      </SmallAreaHeading>
      {
        DRAGONS.map(dragon => (<LabeledTile is={JIAN[dragon]} label={LABELS[dragon].en} size="lg" key={'dragon-tile' + dragon} />))
      }
    </TilesScrollable>

    <SubsectionHeading>
      <SmallAreaHeading>
        <SubsectionTitle>花牌</SubsectionTitle>
      </SmallAreaHeading>
      <DividingLine />
      <SmallAreaHeading>
        <SubsectionTitle>flowers</SubsectionTitle>
      </SmallAreaHeading>
    </SubsectionHeading>
    <SubsectionSubtitle>1 of each tile</SubsectionSubtitle>

    <TilesScrollable>
      <SmallAreaHeading>
        <ScrollableTitle>花牌</ScrollableTitle>
        <ScrollableTitle>flowers</ScrollableTitle>
      </SmallAreaHeading>
      {
        WINDS.map((wind, i) => (<LabeledTile is={HUA[i + 1]} label={LABELS[wind].en} size="lg" key={'hua-1-tile' + wind} />))
      }
    </TilesScrollable>


    <TilesScrollable>
      <SmallAreaHeading>
        <ScrollableTitle>天牌</ScrollableTitle>
        <ScrollableTitle>seasons</ScrollableTitle>
      </SmallAreaHeading>
      {
        WINDS.map((wind, i) => (<LabeledTile is={HUA[i + 5]} label={LABELS[wind].en} size="lg" key={'hua-2-tile' + wind} />))
      }
    </TilesScrollable>

  </>
)

const ShuffleButton = styled.div`
  height: 80px;
  width: 80px;
  min-height: 80px;
  min-width: 80px;
  max-height: 80px;
  max-width: 80px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 20px;
  font-size: 4em;
  line-height: 1.15em;

  background-color: white;
  border: 8px solid #ff90e8;
  cursor: pointer;
  text-align: center;
  box-shadow: 8px 8px 0 rgb(210, 115, 191);
  
  transition: all 300ms;
  transition-delay: 200ms;
  
  &:hover, &:active {
    transition: none;
    box-shadow: none;
    background-color: rgb(210, 115, 191);
    transform: translateX(8px) translateY(8px);
  }
`

const HandDescription = styled.div`
  display: flex;
  padding: 20px 64px;
  justify-content: stretch;
  align-items: center;
  gap: 80px;
`

const HandText = styled.div`
  flex-grow: 2;
  font-size: 2.8em;
  font-weight: 200;
  text-align: right;
`

const ExampleHandHeading = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 64px;
  margin-top: 84px;
`
const ExampleHandHeadingText = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 36px;
`

const ExampleHandTitle = styled.h4`
  font-size: 2.8em;
  margin: 0;
  font-weight: 900;
  text-align: center;
`

const ExampleHand = ({ name, description, fan, defaultTiles = [], randomize }) => {
  const [tiles, setTiles] = useState(defaultTiles)

  return (
    <>
      <ExampleHandHeading>
        <ExampleHandHeadingText>
          <ExampleHandTitle>{fan}番</ExampleHandTitle>
        </ExampleHandHeadingText>
        <DividingLine />
        <ExampleHandHeadingText>
          <ExampleHandTitle>{name}</ExampleHandTitle>
        </ExampleHandHeadingText>
      </ExampleHandHeading>
      <Hand size="sm" of={tiles} />
      <HandDescription>
        {
          randomize && (
            <ShuffleButton onClick={() => setTiles(randomize())}>🔀</ShuffleButton>
          )
        }
        <HandText>{description}</HandText>
      </HandDescription>
    </>
  )

}



const HandsContent = () => (
  <>
    <Text margin="80px 20px 24px 20px" align="center">
      Winning hands are typically formed by having 4 <em>Melds</em> (triplet) and a <em>Pair</em> - which is 14 tiles in total.
      For example:
    </Text>

    <HandOfTiles gap={10}>
      <TileSet size="sm" of={[TIAO[1], TIAO[1], TIAO[1]]} />
      <TileSet size="sm" of={[TIAO[4], TIAO[5], TIAO[6]]} />
      <TileSet size="sm" of={[BING[1], BING[1], BING[1]]} />
      <TileSet size="sm" of={[BING[7], BING[8], BING[9]]} />
      <TileSet size="sm" of={[WAN[8], WAN[8]]} />
    </HandOfTiles>

    <TileSetWithExplanation>
      <TileSet size="md" of={[TIAO[1], TIAO[1], TIAO[1]]} />
      <TileSetExplanation>
        <Text><em>Pong</em> (Match) Meld<br />of 1 Sticks</Text>
      </TileSetExplanation>
    </TileSetWithExplanation>
    <TileSetWithExplanation>
      <TileSet size="md" of={[TIAO[4], TIAO[5], TIAO[6]]} />
      <TileSetExplanation>
        <Text><em>Chi</em> (Run) Meld<br />of 4-5-6 Sticks</Text>
      </TileSetExplanation>
    </TileSetWithExplanation>
    <TileSetWithExplanation>
      <TileSet size="md" of={[BING[1], BING[1], BING[1]]} />
      <TileSetExplanation>
        <Text><em>Pong</em> (Match) Meld<br />of 1 Cookies</Text>
      </TileSetExplanation>
    </TileSetWithExplanation>
    <TileSetWithExplanation>
      <TileSet size="md" of={[BING[7], BING[8], BING[9]]} />
      <TileSetExplanation>
        <Text><em>Chi</em> (Run) Meld<br />of 7-8-9 Cookies</Text>
      </TileSetExplanation>
    </TileSetWithExplanation>
    <TileSetWithExplanation>
      <TileSet size="md" of={[WAN[8], WAN[8]]} />
      <TileSetExplanation>
        <Text><em>Pair</em> of 80,000</Text>
      </TileSetExplanation>
    </TileSetWithExplanation>

    <Text margin="80px 20px 0 20px" align="center">
      Hands have different <em>番 fān</em> (point values) based on how difficult it is to form them.
      Below are the possible hands categorized, with their point values (based on Hong Kong style scoring).
      There are also some special hands that deviate from the typical 4 Meld + 1 Pair format.
    </Text>

    <ExampleHand
      name="chicken hand"
      description="Mixed Suits, with both Pong and Chi"
      fan="0"
      defaultTiles={[
        [TIAO[1], TIAO[2], TIAO[3]],
        [WAN[4], WAN[4], WAN[4]],
        [BING[1], BING[2], BING[3]],
        [BING[9], BING[9], BING[9]],
        [JIAN.BAIPI, JIAN.BAIPI]
      ]}
      randomize={createChickenHand}
    />

    <ExampleHand
      name="common hand"
      description="Every Meld is Chi"
      fan="1"
      defaultTiles={[[TIAO[1],
      TIAO[2],
      TIAO[3],], [WAN[4],
      WAN[5],
      WAN[6],], [BING[1],
      BING[2],
      BING[3],], [BING[7],
      BING[8],
      BING[9],], [WAN[8],
      WAN[8],]]}
      randomize={createCommonHand}
    />

    <ExampleHand
      name="all pong"
      description="Every Meld is Pong"
      fan="3"
      defaultTiles={[[TIAO[1],
      TIAO[1],
      TIAO[1]], [WAN[4],
      WAN[4],
      WAN[4],], [BING[3],
      BING[3],
      BING[3],], [BING[7],
      BING[7],
      BING[7],], [WAN[8],
      WAN[8],]]}
      randomize={createAllPongsHand}
    />

    <ExampleHand
      name="mixed one suit"
      description="One Suit of Pong and Chi, with Honors"
      fan="3"
      defaultTiles={[
        [TIAO[1],
        TIAO[1],
        TIAO[1],], [TIAO[7],
        TIAO[8],
        TIAO[9],], [JIAN.ZHONG,
        JIAN.ZHONG,
        JIAN.ZHONG,], [TIAO[5],
        TIAO[5],
        TIAO[5],], [TIAO[2],
        TIAO[2],]
      ]}
      randomize={createMixedOneSuitHand}
    />

    <ExampleHand
      name="all pairs"
      description="7 Pairs regardless of Suit"
      fan="4"
      defaultTiles={[
        [FENG.DONG,
        FENG.DONG,], [WAN[5],
        WAN[5],], [TIAO[9],
        TIAO[9],], [JIAN.FA,
        JIAN.FA,], [BING[2],
        BING[2],], [BING[5],
        BING[5],], [WAN[2],
        WAN[2],]
      ]}
      randomize={createAllPairsHand}
    />

    <ExampleHand
      name="small dragons"
      description="2 any Melds, 2 Dragon Melds, and 1 Dragon Pair"
      fan="5"
      defaultTiles={[
        [WAN[1],
        WAN[1],
        WAN[1],], [TIAO[7],
        TIAO[8],
        TIAO[9],], [JIAN.ZHONG,
        JIAN.ZHONG,
        JIAN.ZHONG,], [JIAN.FA,
        JIAN.FA,
        JIAN.FA,], [JIAN.BAIPI,
        JIAN.BAIPI,]
      ]}
      randomize={createSmallDragonsHand}
    />

    <ExampleHand
      name="small winds"
      description="1 any Meld, 3 Wind Melds, and 1 Wind Pair"
      fan="6"
      defaultTiles={[
        [BING[4],
        BING[5],
        BING[6],], [FENG.DONG,
        FENG.DONG,
        FENG.DONG,], [FENG.BEI,
        FENG.BEI,
        FENG.BEI,], [FENG.NAN,
        FENG.NAN,
        FENG.NAN,], [FENG.XI,
        FENG.XI,]
      ]}
      randomize={createSmallWindsHand}
    />

    <ExampleHand
      name="all one suit"
      description="One suit of Pong and Chi, without Honors"
      fan="7"
      defaultTiles={[
        [BING[4],
        BING[5],
        BING[6],], [BING[5],
        BING[6],
        BING[7],], [BING[1],
        BING[1],
        BING[1],], [BING[9],
        BING[9],
        BING[9],], [BING[2],
        BING[2],]
      ]}
      randomize={createAllOneSuitHand}
    />

    <ExampleHand
      name="big dragons"
      description="3 Dragon Melds, 1 any Meld, and 1 any Pair"
      fan="8"
      defaultTiles={[
        [JIAN.ZHONG,
        JIAN.ZHONG,
        JIAN.ZHONG,], [JIAN.FA,
        JIAN.FA,
        JIAN.FA,], [JIAN.BAIPI,
        JIAN.BAIPI,
        JIAN.BAIPI,], [WAN[2],
        WAN[3],
        WAN[4],], [TIAO[8],
        TIAO[8],]
      ]}
      randomize={createBigDragonsHand}
    />

    <ExampleHand
      name="terminals"
      description="Melds and a Pair of 1s, 9s, or Honors"
      fan="10"
      defaultTiles={[
        [WAN[9],
        WAN[9],
        WAN[9],], [TIAO[1],
        TIAO[1],
        TIAO[1],], [TIAO[9],
        TIAO[9],
        TIAO[9],], [BING[1],
        BING[1],
        BING[1],], [BING[9],
        BING[9],]
      ]}
      randomize={createTerminalsHand}
    />

    <ExampleHand
      name="nine gates"
      description="In one suit: 111 2345678 999, and any tile from that suit"
      fan="10"
      defaultTiles={[
        [TIAO[1],
        TIAO[1],
        TIAO[1],], [TIAO[2],
        TIAO[3],
        TIAO[4],
        TIAO[5],
        TIAO[6],
        TIAO[7],
        TIAO[8],], [TIAO[9],
        TIAO[9],
        TIAO[9],], [TIAO[9],]
      ]}
      randomize={createNineGatesHand}
    />

    <ExampleHand
      name="big winds"
      description="4 Wind Melds, and 1 any Pair"
      fan="13"
      defaultTiles={[
        [FENG.DONG,
        FENG.DONG,
        FENG.DONG,], [FENG.BEI,
        FENG.BEI,
        FENG.BEI,], [FENG.NAN,
        FENG.NAN,
        FENG.NAN,], [FENG.XI,
        FENG.XI,
        FENG.XI,], [BING[8],
        BING[8],]
      ]}
      randomize={createBigWindsHand}
    />

    <ExampleHand
      name="thirteen orphans"
      description="One of each 1, 9, Dragon, and Wind + a duplicate"
      fan="13"
      defaultTiles={[
        [FENG.DONG,
        FENG.NAN,
        FENG.XI,
        FENG.BEI,], [BING[1],
        BING[9],], [TIAO[1],
        TIAO[9],], [WAN[1],
        WAN[9],], [JIAN.ZHONG,
        JIAN.FA,
        JIAN.BAIPI,], [JIAN.BAIPI,]
      ]}
      randomize={createThirteenOrphansHand}
    />


  </>
)

const wt = [[0, 75], [75, 0], [0, -75], [-75, 0]]
const getWindAnimation = (i) => keyframes`
 0%, 20%, 100% { transform: translateX(${wt[i%4][0]}%) translateY(${wt[i%4][1]}%); }
 25%, 45% { transform: translateX(${wt[(i+1)%4][0]}%) translateY(${wt[(i+1)%4][1]}%); }
 50%, 70% { transform: translateX(${wt[(i+2)%4][0]}%) translateY(${wt[(i+2)%4][1]}%); }
 75%, 95% { transform: translateX(${wt[(i+3)%4][0]}%) translateY(${wt[(i+3)%4][1]}%); }
`
const WindBoardText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  grid-area: windboard;

  animation-name: ${({i}) => getWindAnimation(i)};
  animation-duration: ${({ duration = 20 }) => duration}s;
  animation-iteration-count: infinite;
  ${({ highlight }) => highlight ? `
    border-radius: 40px;
    background-color: white;
    color: red;
    margin: 100px;
  ` : ``}
`

const YourSeat = styled.div`
  grid-area: windbottom;
  border-radius: 40px;
  background-color: rgb(113, 8, 51);
  margin: 40px 40px 20px;
`

const WindText = styled.h4`
  font-size: ${({ char }) => char ? 5 : 2.8}em;
  margin: 0;
  font-weight: ${({ char, subtitle }) => char || subtitle ? 200 : 900};
  text-align: center;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const WindBoard = styled.div`
  grid-area: windboard;
  border: 5px solid white;
`

const WindDiagramContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-areas:
  ". windtop windtop ."
  "windleft windboard windboard windright"
  "windleft windboard windboard windright"
  ". windbottom windbottom .";
  justify-items: stretch;
  align-items: stretch;
  height: 100vw;
  width: 100vw;
`

const getPrevailingWindAnimation = (i) => keyframes`
  0%, 20%, 96% { opacity: ${i === 0 ? 1 : 0}; }
  21%, 45% { opacity: ${i === 1 ? 1 : 0}; }
  46%, 70% { opacity: ${i === 2 ? 1 : 0}; }
  71%, 95% { opacity: ${i === 3 ? 1 : 0}; }
`
const PrevailingWindText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  grid-area: windboard;

  animation-name: ${({i}) => getPrevailingWindAnimation(i)};
  animation-duration: ${({duration}) => duration}s;
  animation-iteration-count: infinite;
  animation-delay: ${({duration}) => duration / 25}s;
  border-radius: 40px;
  background-color: white;
  color: red;
  margin: 100px;
`

const SeatWindDiagram = () => {
  const winds = ['east', 'south', 'west', 'north']
  const feng = ['東','南','西','北']
  return (
    <WindDiagramContainer>
      <YourSeat />
      <WindBoardText i={0} highlight rotate>
        <WindText char>{feng[0]}</WindText>
        <WindText>{winds[0]}</WindText>
        <WindText subtitle>dealer</WindText>
      </WindBoardText>
      <WindBoardText i={1} rotate>
        <WindText char>{feng[1]}</WindText>
        <WindText>{winds[1]}</WindText>
      </WindBoardText>
      <WindBoardText i={2} rotate>
        <WindText char>{feng[2]}</WindText>
        <WindText>{winds[2]}</WindText>
      </WindBoardText>
      <WindBoardText i={3} rotate>
        <WindText char>{feng[3]}</WindText>
        <WindText>{winds[3]}</WindText>
      </WindBoardText>
      <WindBoard />
    </WindDiagramContainer>
  )
}

const PrevailingWindDiagram = () => {
  const winds = ['east', 'south', 'west', 'north']
  const feng = ['東','南','西','北']
  const fullBoardRotation = 40;
  return (
    <WindDiagramContainer>
      <YourSeat />
      <WindBoardText duration={fullBoardRotation / 4} i={0}>
        <WindText char>{feng[0]}</WindText>
        <WindText>{winds[0]}</WindText>
      </WindBoardText>
      <WindBoardText duration={fullBoardRotation / 4} i={1}>
        <WindText char>{feng[1]}</WindText>
        <WindText>{winds[1]}</WindText>
      </WindBoardText>
      <WindBoardText duration={fullBoardRotation / 4} i={2}>
        <WindText char>{feng[2]}</WindText>
        <WindText>{winds[2]}</WindText>
      </WindBoardText>
      <WindBoardText duration={fullBoardRotation / 4} i={3}>
        <WindText char>{feng[3]}</WindText>
        <WindText>{winds[3]}</WindText>
      </WindBoardText>

      <PrevailingWindText duration={fullBoardRotation} i={3} rotate>
        <WindText char>{feng[3]}</WindText>
        <WindText>{winds[3]}</WindText>
        <WindText subtitle>last round</WindText>
      </PrevailingWindText>
      <PrevailingWindText duration={fullBoardRotation} i={2} rotate>
        <WindText char>{feng[2]}</WindText>
        <WindText>{winds[2]}</WindText>
        <WindText subtitle>3rd round</WindText>
      </PrevailingWindText>
      <PrevailingWindText duration={fullBoardRotation} i={1} rotate>
        <WindText char>{feng[1]}</WindText>
        <WindText>{winds[1]}</WindText>
        <WindText subtitle>2nd round</WindText>
      </PrevailingWindText>
      <PrevailingWindText duration={fullBoardRotation} i={0} highlight rotate>
        <WindText char>{feng[0]}</WindText>
        <WindText>{winds[0]}</WindText>
        <WindText subtitle>first round</WindText>
      </PrevailingWindText>
      <WindBoard />
    </WindDiagramContainer>
  )
}

const ScoringContent = () => (
  <>
    <Text margin="80px 20px 24px 20px" align="center">
      In Hong Kong scoring, players usually decide on a hand minimum and a hand maximum before playing.
      3番 minimum and 8番 maximum is typical, meaning a common hand cannot be used to win, and a high scoring
      hand like nine gates would only count as 8番.
      <br /><br />
      Aside from the hand, there are additional ways to get extra 番 depending on the presence of certain tiles, and
      how you achieve the winning hand.
    </Text>

    <SeatWindDiagram />
    <Text margin="80px 20px 24px 20px" align="center">
      The <em>Seat Wind</em> is the wind at the direction you are seated at, where the dealer is always the East.
      After every hand, the dealer and the Seat Wind moves counter-clockwise. If your hand has a Meld or Pair of your
      Seat Wind, you get 1番.
    </Text>

    <PrevailingWindDiagram />
    <Text margin="80px 20px 24px 20px" align="center">
      The <em>Prevailing Wind</em> is the wind for the entire table. It starts with East, and changes
      every round (4 hands). The order is East, South, West, North. If your hand has a Meld or Pair of the
      Prevailing Wind, you get 1番.
    </Text>
  </>
)

export const App2 = () => {

  return (
    <AppContainer>

      <TitleConceptSection>
        <SectionRow>
          <Title>麻將</Title>
          <Title>mahjong</Title>
        </SectionRow>
      </TitleConceptSection>

      <TilesConceptSection>
        <ConceptRow>
          <ConceptTitle>紙牌</ConceptTitle>
          <ConceptTitle>the tiles</ConceptTitle>
        </ConceptRow>
        <TilesContent />
      </TilesConceptSection>

      <HandsConceptSection>
        <ConceptRow>
          <ConceptTitle>麻將</ConceptTitle>
          <ConceptTitle>the hands</ConceptTitle>
        </ConceptRow>
        <HandsContent />
      </HandsConceptSection>

      <ScoringConceptSection>
        <ConceptRow>
          <ConceptTitle>麻將</ConceptTitle>
          <ConceptTitle>scoring</ConceptTitle>
        </ConceptRow>
        <ScoringContent />
      </ScoringConceptSection>

    </AppContainer>
  )

}
