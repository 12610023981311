import React from 'react'
import { styled } from 'styled-components'
import { SPRITESHEET_HEIGHT, SPRITESHEET_WIDTH, TILE_HEIGHT, TILE_SCALES, TILE_WIDTH } from './TileConstants'

const TileSprite = styled.div`
  ${({scale}) => scale >= 1 ? `
    border-style: solid;
    border-color: rgb(221, 217, 185);
    border-width: ${6 * scale}px ${6 * scale}px ${2 * scale}px ${2 * scale}px;
  ` : ``}
  border-radius: ${({scale}) => 16 * scale}px;

  background-image: url("tiles.png");
  width: ${({scale}) => TILE_WIDTH * scale}px;
  height: ${({scale}) => TILE_HEIGHT * scale}px;
  background-position: ${({x, scale}) => -x * scale}px ${({y, scale}) => -y * scale}px;
  background-size: ${({scale}) => `${SPRITESHEET_WIDTH * scale}px ${SPRITESHEET_HEIGHT * scale}px`};
`

const TileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
`

const TileLabel = styled.h5`
  font-size: 2.8em;
  margin: 0;
  font-weight: 200;
  text-align: center;
  color: #fff;
`

export const LabeledTile = ({is, size, label}) => {
  const scale = TILE_SCALES[size]

  return (
    <TileContainer scale={scale}>
      <Tile is={is} size={size} />
      <TileLabel>{label}</TileLabel>
    </TileContainer>
  )
}

export const Tile = ({ is, size = 'md' }) => {
  const [x, y] = is
  const scale = TILE_SCALES[size]

  return (
    <TileSprite x={x} y={y} scale={scale}/>
  )
}
